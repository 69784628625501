/*** GENERAL STYLES ***/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
  line-height: 1.5em !important;
  margin: 0 !important;
  padding: 0 !important;
  color: black;
  /* margin-bottom: 16px !important; */
}

h1 {
  font-size: 34px !important;
}
h2 {
  font-size: 30px !important;
  color: rgb(70, 70, 70) !important;
}
h3 {
  font-size: 26px !important;
}
h4 {
  font-size: 22px !important;
}
h5 {
  font-size: 20px !important;
}
h6 {
  font-size: 18px !important;
}

h1:not(:last-child),
h2:not(:last-child),
h3:not(:last-child),
h4:not(:last-child),
h5:not(:last-child),
h6:not(:last-child) {
  margin-bottom: 16px !important;
}

.snappy {
  font-weight: bold;
  color: #ace9ac !important;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

p {
  font-size: 18px !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 1.5em !important;
  margin-bottom: 16px !important;
}

.yesno .gridItem p:only-child{
  margin-bottom: 0 !important;
}

a {
  font-weight: bold;
  color: #3182ce !important;
}

.maindisclaimer a {
  text-decoration: underline !important;
}

ul,
ol {
  margin: 24px;
  margin-top: 0;
}

ul > li,
ol > li {
  padding: 8px;
  font-size: 18px;
}

ul > li > .chakra-icon,
ol > li > .chakra-icon {
  margin-right: 12px;
}

.tac ul {
  list-style: disc;
}

.home-main li {
  font-size: 16px;
}

label {
  font-weight: bold !important;
  font-size: 16px !important;
  color: #696969;
  margin: 0 !important;
}

label:has(+ input),
label:has(+ div > select),
label:has(+ textarea) {
  margin-bottom: 12px !important;
}

td label:last-child {
  font-size: 10px !important;
}

input,
select,
textarea {
  border: 1px solid #999 !important;
  /* color: #555;
  font-weight: bold; */
}

input[type="date"]{
  /* width: auto !important; */
}

input[aria-readonly="true"],
select[aria-readonly="true"]
{
  color: gray;
  font-style: italic;
}

.bannerGotoSelect option {
  background-color: black !important;
  color: white !important;
}

.bannerGotoSelect select:focus,
.bannerGotoSelect option:checked {
  background-color: #3182ce;
}

.reviewGrid input,
.reviewGrid select {
  font-size: 12px;
  font-weight: bold;
  padding: 2px 4px;
  height: 20px;
  border-radius: 0;
  border-color: #3182ce !important;
  color: #3182ce;
}

img:not(.logo) {
  width: 475px;
  object-fit: cover;
  border-radius: 13px;
  box-shadow: 2px 3px 13px gray;
}

.home-main img:not(.logo) {
  width: 100%;
}

img.card {
  width: 375px;
  height: 275px;
  box-shadow: none;
}

button:not([aria-label="Close"]){
  min-width: 64px;
}

button[aria-label="Next"],
button[aria-label="Previous"]{
  width: 120px;
}

/*** MODAL STYLES ***/

.chakra-modal__content-container label {
  font-size: 16px !important;
}

.chakra-modal__header {
  font-size: 24px !important;
  font-weight: bold !important;
}

/*** CLASS STYLES ***/

.home-banner,
.nav-banner {
  /* border: 2px solid red; */
  background-color: black;
  color: white;
  padding: 24px 0 26px 0;
  text-align: center;
}

.home-main,
.existing-business-details {
  padding-top: 60px;
  padding-bottom: 0;
}

.home-main h2,
.existing-business-details h2 {
  color: black;
  padding-bottom: 24px;
}

.home-main img,
.existing-business-details img {
  border-radius: 13px;
}

.divider {
  height: 2px;
  background-color: black;
  margin: 24px 0;
}

.subdivider {
  height: 2px;
  background-color: silver;
  margin: 24px 0;
}

table.business-types-matrix,
table.corporation-types-matrix {
  width: 100%;
}

table.business-types-matrix td,
table.business-types-matrix th,
table.corporation-types-matrix td,
table.corporation-types-matrix th {
  text-align: center;
}

table.business-types-matrix td:first-child,
table.corporation-types-matrix td:first-child {
  text-align: left;
}

table.payment th {
  font-weight: bold;
  color: black;
}

table.payment tfoot td {
  border-top: 2px dashed #999 !important;
}

table.review th {
  color: black;
  text-align: left !important;
}

table.review td {
  padding: 0 20px;
}

.btn-black {
  background-color: rgb(17, 35, 46) !important;
  color: white !important;
  border: 1px solid black !important;
  min-width: 80px !important;
}

.btn-black:hover {
  background-color: #ebedf0 !important;
  color: black !important;
  border: 1px solid black !important;
}

.disclaimer {
  height: 50px;
  margin-top: 50px;
}

.disclaimer .container {
  border-top: 1px solid gray;
  padding-top: 12px;
  height: 100px;
}

.disclaimer p {
  color: gray;
  font-size: 16px !important;
  font-style: italic;
}

.popover-trigger-icon {
  margin: 0 !important;
  margin-right: 8px !important;
}

  /* .popover-trigger-icon:has(+ p) {
    margin-bottom: 16px !important;
  } */

.popover-content {
  padding: 16px !important;
  background-color: #fcfab1 !important;
  font-size: 14px !important;
  font-weight: bold;
  color: rgb(105, 92, 65);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5) !important;
}

.popover-arrow {
  background-color: #fcfab1 !important;
}

table.business-types-matrix .popover-trigger-icon,
table.corporation-types-matrix .popover-trigger-icon {
  margin-top: -16px;
}

.price {
  font-weight: bold;
  color: #38a169;
}

.home-main ul {
  margin-top: 0;
  margin-left: 0;
}

.home-main ul > li {
  padding: 0;
}



.sidebar,
.maincomponent {
  
}

.sidebar {
  padding: 16px;
  border: solid 1px silver;
  border-radius: 9px;
  background-color: rgba(0,0,0,0.15);
}



/*** CHAKRA OVERRIDES ***/

.chakra-radio__control {
  border-color: gray !important;
}


/*** RESPONSIVE STYLES ***/

@media (min-width: 198px) {

  /* .nav-banner {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    margin-bottom: 300px;
  } */

  .home-banner,
  .nav-banner {
    height: 100px;
  }

  .home-banner .logo {
    position: absolute;
    width: 90px;
    top: 4px;
    left: 4px;
  }

  .container {
    width: 90%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .bannerGotoSelect {
    display: block;
  }

  .bannerGotoButtons {
    display: none;
  }

  .sidebar {
    display: none;
    /* position: fixed;
    left: 0;
    top: 0;
    background-color: rgb(217, 217, 217);
    width: 60% !important;
    z-index: 10;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.5); */
  }
  
  .maincomponent {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .home-main,
  .existing-business-details {
    padding-top: 24px;
  }
  
  .minutebook {
    padding-right: 0; 
  }

  .nextprevious {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: black;
    width: 100%;
    padding: 12px;
    text-align: center;
    box-shadow: 0px -4px 12px rgba(0,0,0,0.5);
    z-index:10;
  }

  /* TODO: lock heading and subheading to top */

  /* .formPageContent {
    margin-top: 120px !important;
  }

  .formPageHeading {
    position: fixed;
    top: 140px;
    background: white;
  }

  .formPageSubHeading {
    position: fixed;
    top: 180px;
    background: white;
  } */

  .disclaimer {
    padding-bottom: 240px;
  }
}

@media (min-width: 780px) {

  .home-banner,
  .nav-banner {
    height: auto;
  }

  .container {
    width: 90%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .bannerGotoSelect {
    display: none;
  }

  .bannerGotoButtons {
    display: block;
  }

  .sidebar {
    width: 25% !important;
    display: block;
  }

  .maincomponent {
    width: 75% !important;
    padding-left: 32px !important;
  }  

  .minutebook {
    padding-right: 24px;
  }

  .disclaimer {
    padding-bottom: 180px;
  }
}

@media (min-width: 820px) {
  .container {
    width: 80%;
    max-width: 1000px;
  }

  .home-banner .logo {
    position: static;
    top: auto;
    left: auto;
    width: 250px;
  }

}

@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}
